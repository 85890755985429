import React from 'react';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import Button from '@mui/material/Button';
import "slick-carousel/slick/slick-theme.css";
import Typography from '@mui/material/Typography';

const Widgets = ({ widget, widgetStyle, widgetProps }) => {
    const { type } = widget;
    const widgets = () => {
        switch (type) {
            case 'text':
                return (
                    <Typography 
                        sx={[widgetStyle ?? {}, {a: {color: '#06c'}}]} 
                        dangerouslySetInnerHTML={{ __html: widgetProps.data ?? '' }} 
                    />
                );

            case 'button':
                return (
                    <Button
                        variant="contained"
                        {...(widgetProps.href ? { LinkComponent: Link, to: `${widgetProps.href}` } : {})}
                        sx={[widgetStyle ?? {}, widgetProps.backgroundHover && { ':hover': { backgroundColor: widgetProps.backgroundHover } }, widgetProps.colorHover && { ':hover': { color: widgetProps.colorHover } }, widgetProps.borderColorHover && { ':hover': { borderColor: widgetProps.borderColorHover } },]}>
                        {widgetProps.data ?? 'Button'}
                    </Button>
                );

            case 'image':
                return (
                    <Box sx={[widgetStyle ?? {}]}>
                        <Box component={'img'} alt={widgetProps.imageUrl} loading="lazy" src={widgetProps.imageUrl ?? 'https://fundway.com/assets/images/logo.png'} sx={{ width: '100%', objectFit: widgetProps.objectFit ?? 'cover', height: '100%' }} />
                    </Box>
                );

            case 'imageGallery':
                return (
                    <Box sx={[widgetStyle ?? {}, { minHeight: '100px' }]} className="slider-container">
                        <Slider
                            arrows={widgetProps?.arrows ? JSON.parse(widgetProps?.arrows) : true}
                            dots={widgetProps?.dots ? JSON.parse(widgetProps?.dots) : true}
                            infinite={widgetProps.infinite ?? true}
                            speed={widgetProps.speed ?? 300}
                            slidesToShow={widgetProps.slidesToShow ?? 1}
                            slidesToScroll={widgetProps.slidesToScroll ?? 1}
                            autoplay={widgetProps.autoplay ?? true}
                            autoplaySpeed={widgetProps.autoplaySpeed ?? 5000}
                            cssEase={'linear'}
                        >
                            {
                                widgetProps?.imageUrl?.map((image, imageIndex) => (
                                    <Box key={imageIndex} sx={{ height: widgetProps.containerHeight ?? 200, width: '100%' }}>
                                        <Box component={'img'} alt={image} loading="lazy" src={image} sx={{ objectFit: 'cover', height: 1, width: 1 }} />
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                );

            case 'video':
                return (
                    <Box sx={{ height: 1, width: 1, position: 'relative' }}>
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1 }}></Box>
                        <Box
                            component={YouTube}
                            opts={{
                                height: '100%',
                                width: '100%',
                                playerVars: {
                                    playlist: widgetProps.videoId ?? 'https://www.youtube.com/embed/TAj2K1jYec8?playlist=TAj2K1jYec8&loop=1&autoplay=1&controls=0&mute=1&enablejsapi=1&disablekb=1&origin=https%3A%2F%2Fhyper-active.com&widgetid=1',
                                    loop: 1,
                                    autoplay: 1,
                                    controls: 0,
                                    mute: 1,
                                    enablejsapi: 1,
                                    disablekb: 1,
                                    origin: 'https://hyper-active.com/'
                                },
                            }}
                            sx={{
                                width: 1,
                                height: 1,
                                borderRadius: '20px',
                                overflow: 'hidden'
                            }}
                            videoId={widgetProps.videoId ?? 'https://www.youtube.com/embed/TAj2K1jYec8?playlist=TAj2K1jYec8&loop=1&autoplay=1&controls=0&mute=1&enablejsapi=1&disablekb=1&origin=https%3A%2F%2Fhyper-active.com&widgetid=1'}
                            title={'Youtube Video Player'}
                        />
                    </Box>
                );

            default:
                return null;
        }
    }

    return (
        <Grid size={widgetProps?.itemSize ?? { lg: 12, md: 12, sm: 12, xs: 12 }} sx={[{ height: widgetProps.containerHeight ?? 'unset', position: 'relative', zIndex: 2 }]}>
            {widgets()}
        </Grid>
    );
}

export default Widgets;
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FetchData from '../../api/FetchData';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Loader from '../../components/loader/Loader';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
// import SimpleMortgageCalculator from '../../components/card/SimpleMortgageCalculator';
import { AnimatePresence, motion } from 'framer-motion';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SeaView from '../../layout/views/SeaView';
import PromonadeView from '../../layout/views/PromonadeView';
import MarinaView from '../../layout/views/MarinaView';
import { CircularProgress, FormControl, MenuItem, NativeSelect, Select } from '@mui/material';
import Countries from '../public/Countries.json'
import { deleteUser, financingType, getUserData, saveUserData } from '../../utils/utils';
import NormalCalculator from '../../components/card/NormalCalculator';
import { trackCustomEvent } from '../../utils/facebookPixel';

const info = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #000',
    justifyContent: 'space-between',
}


const inputStyle = {
    backgroundColor: '#FFF',
    '.MuiInputBase-root': {
        borderColor: '#FFF',
        color: '#000'
    },
    fieldset: {
        borderRadius: 0,
        borderWidth: '1px',
        borderColor: '#e0e0e0',
        color: '#000',
    }
}

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

const defaultForm = {
    firstname: getUserData()?.firstname ? getUserData().firstname : '',
    lastname: getUserData()?.lastname ? getUserData().lastname : '',
    email: getUserData()?.email ? getUserData().email : '',
    tel: getUserData()?.tel ? getUserData().tel : '',
    prefix: getUserData()?.prefix ? getUserData().prefix : '',
    countryName: getUserData()?.countryName ? getUserData().countryName : '',
    countryId: getUserData()?.countryId ? getUserData().countryId : '',
}

const defaultErrorForm = {
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    telError: false,
    prefixError: false,
    countryError: false,
}

export default function RezidenciesDetails() {
    const language = useSelector((state) => state.language.language);
    const id = useParams();
    const [apartment, setApartment] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: id.id })
            .then(res => {
                setApartment(res?.data?.data[0]);
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            })
        window.scrollTo(0, 0);
    }, [id.id])

    const [openModal, setOpenModal] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false);
    const [alert, setAlert] = useState(defaultAlert)

    const [formData, setFormData] = useState(defaultForm)
    const [formDataErrors, setFormDataErrors] = useState(defaultErrorForm)

    const inputFunc = {
        firstnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, firstnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, firstnameError: false }) } setFormData({ ...formData, firstname: value }) },
        lastnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, lastnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, lastnameError: false }) } setFormData({ ...formData, lastname: value }) },
        emailInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) { setFormDataErrors({ ...formDataErrors, emailError: true }) } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") { setFormDataErrors({ ...formDataErrors, emailError: false }) } setFormData({ ...formData, email: value }) },
        prefixInput: (e) => { var value = e.target.value; if (value !== "") { setFormDataErrors({ ...formDataErrors, prefixError: false }) } setFormData({ ...formData, prefix: value }); },
        telInput: (e) => { var value = e.target.value; const regex = /^[0-9\s]*$/; if (value !== "" && !value.match(regex)) { setFormDataErrors({ ...formDataErrors, telError: true }) } else if (value.match(regex) || value !== "") { setFormDataErrors({ ...formDataErrors, telError: false }) } setFormData({ ...formData, tel: value }) },
        countryInput: (e) => {
            var targetOption = e.target.options[e.target.selectedIndex];
            var value = e?.target?.value;
            var countryname = targetOption.getAttribute('data-countryname');
            if (value !== "") {
                setFormDataErrors({
                    ...formDataErrors,
                    countryError: false
                })
            };
            setFormData({
                ...formData,
                countryId: value,
                countryName: countryname,
            });
        },
    }

    const valiedateData = () => {
        setFormDataErrors(prevErrors => ({
            ...prevErrors,
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            countryError: formData.countryName === '' || formData.countryName === null,
        }));

        const errors = {
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            countryError: formData.countryName === '' || formData.countryName === null,
        }
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(valiedateData());
        const confirmation = validation.includes(true);
        if (!confirmation) {
            setLoadingButton(true)
            FetchData.create('/email/confirmUser', {
                userData: formData,
                lang: language,
                typeOf: 'downloadPlan',
                aptId: id.id,
                fileLocation: `../documents/${apartment.pdfFileName}`,
                building: `${language === 'eng' ? apartment.apartmentName.replace('Apartamenti', 'Property') : apartment.apartmentName} ${language === 'eng' ? apartment.buildingName.replace('Godina', 'Building') : apartment.buildingName} ${apartment.objectName}`
            })
                .then(res => {
                    if (res?.data?.success) {
                        trackCustomEvent('ViewContent', {
                                Apartment_id: id, 
                                type: 'Download Plan',
                                Date: new Date(),
                                firstname: formData.firstname,
                                lastname: formData.lastname,
                                email: formData.email,
                                tel: formData.tel,
                                prefix: formData.prefix,
                                otherCounty: formData.otherCounty,
                                country: formData.countryName,
                        });

                        handleClose();
                        setAlert({
                            open: true,
                            severity: res?.data?.severity,
                            msg: res?.data?.msg
                        });
                        if (res?.data?.userAlreadyLogged) {
                            deleteUser();
                            setFormData(defaultForm);
                        }
                        if (res?.data?.userAlreadyLogged === false) {
                            saveUserData(
                                {
                                    firstname: formData.firstname,
                                    lastname: formData.lastname,
                                    email: formData.email,
                                    tel: formData.tel,
                                    prefix: formData.prefix,
                                    otherCounty: formData.otherCounty,
                                    country: formData.country,
                                }
                            )
                            handleRidirect(res.data.useToken);
                        }
                        if (res?.data?.notConfirmed === true) {
                            saveUserData(
                                {
                                    firstname: formData.firstname,
                                    lastname: formData.lastname,
                                    email: formData.email,
                                    tel: formData.tel,
                                    prefix: formData.prefix,
                                    otherCounty: formData.otherCounty,
                                    country: formData.country,
                                }
                            )
                            handleRidirect(res.data.useToken);
                        }
                    }
                    setLoadingButton(false);
                    setOpenModal(false);
                })
                .catch(err => {
                })

        } else return;
    }

    const navigate = useNavigate();

    const handleRidirect = (data) => {
        setTimeout(() => {
            navigate(`../userData/?id=${data}&reserveId=${id.id}`);
        }, [3000])
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 5000)
    };

    const [planDetailsModal, setPlanDetailsModal] = useState(false);
    const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
    const [openPayingWithBank, setOpenPayingWithBank] = useState(false);
    const [openCalculator, setOpenCalculator] = useState(false);

    // const pixedAndOpen = (id, loading) => {
    //     if (loading === false) {
    //         setPlanDetailsModal(true)        
    //     }
    //     fbq('track', 'Payment Plan', {Apartment_id: id, Date: new Date()});
    // }

    return (
        <Box color={'#002856'}>
            {
                (loading === false && apartment)
                    ?
                    <Box>
                        <Box>
                            {
                                apartment.typology === 'DUPLEX' ?
                                    <Typography variant="h3" sx={{ fontWeight: 'light', fontSize: '30px' }} textAlign={'center'} marginTop={5} marginBottom={2}>
                                        {
                                            apartment.apartmentId === '1650'
                                                ?
                                                language === 'alb' ? 'DUPLEX 1 Building 1 B2'.replace('Building', 'Godina') : 'DUPLEX 1 Building 1 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartment.apartmentId === '1651'
                                                ?
                                                language === 'alb' ? 'DUPLEX 2 Building 2 B2'.replace('Building', 'Godina') : 'DUPLEX 2 Building 2 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartment.apartmentId === '1652'
                                                ?
                                                language === 'alb' ? 'DUPLEX 3 Building 3 B2'.replace('Building', 'Godina') : 'DUPLEX 3 Building 3 B2'
                                                :
                                                ''
                                        }
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>{language === 'eng' ? apartment.apartmentName.replace('Apartamenti', 'Property') : apartment.apartmentName} {language === 'eng' ? apartment.buildingName.replace('Godina', 'Building') : apartment.buildingName} {apartment.objectName}</Typography>
                            }
                        </Box>

                        <Box
                            sx={{
                                width: {
                                    md: '1024px',
                                    sm: '80%',
                                    xs: '90%'
                                },
                                rowGap: '10px',
                                marginX: 'auto',
                                display: 'grid',
                                gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
                            }}
                        >
                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                    <Box component={'img'} alt='stair' loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>+{apartment.floorNo}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                    <Box component={'img'} alt='beds' loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.typology}</Typography>
                            </Box>


                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                    <Box component={'img'} alt='orientation' loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['orientation'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{lng[language][apartment.orientationName]}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                    <Box component={'img'} alt='terrace' loading="lazy" src='https://fundway.com/images/2024/terrace.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceTerrace ? apartment.surfaceTerrace : '0'} M²</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} width={'300px'}>
                                    <Box component={'img'} alt='scalability' loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['apartmentSize'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceGrossWithoutTerrace} M²</Typography>
                            </Box>

                            {
                                apartment.surfaceTerrace ?
                                    <Box sx={info}>
                                        <Box display={'flex'} alignItems={'center'} width={'300px'}>
                                            <Box component={'img'} alt='scalability' loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                            <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                        </Box>
                                        <Typography fontWeight={500}>{apartment.surfaceGrossWithTerrace} M²</Typography>
                                    </Box>
                                    : ''
                            }
                            {
                                apartment.status === 'free'
                                    ?
                                    <>
                                        <Box sx={info}>
                                            <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                                <Box component={'img'} alt='parking' loading="lazy" src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '24px', xs: '24px' }, height: '100%', objectFit: 'contain' }} />
                                                <Typography marginLeft={'10px'} fontWeight={500}>PARKING</Typography>
                                            </Box>
                                            {/* <Typography fontWeight={500}>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(apartment.priceParking)}</Typography> */}
                                            {
                                                apartment.typology === 'DUPLEX' ?
                                                    <Typography fontWeight={500}>
                                                        {
                                                            apartment.apartmentId === '1650'
                                                                ?
                                                                language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2']
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            apartment.apartmentId === '1651'
                                                                ?
                                                                language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2']
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            apartment.apartmentId === '1652'
                                                                ?
                                                                language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2']
                                                                :
                                                                ''
                                                        }
                                                    </Typography>
                                                    :
                                                    <Typography fontWeight={500}>
                                                        {lng[language]['parking1']}
                                                    </Typography>
                                            }
                                        </Box>
                                        {/* <Box sx={info}>
                                            <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/money.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                                <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['totalPrice'].toUpperCase()}</Typography>
                                            </Box>
                                            <Typography fontWeight={500}>
                                                {new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}
                                            </Typography>
                                        </Box> */}
                                    </>
                                    :
                                    ''
                            }
                        </Box>

                        <Box
                            sx={{
                                marginY: '50px',
                                width: {
                                    md: '1024px',
                                    sm: '80%',
                                    xs: '90%'
                                },
                                marginX: 'auto',
                                height: {
                                    md: 500,
                                    xs: 'auto'
                                },
                            }}
                        >
                            <Box component={'img'} alt={apartment?.imageName} loading="lazy" src={`https://www.fundway.com/images/apartments/${apartment?.imageName}`} sx={{ width: 1, height: 1, objectFit: 'contain' }} />
                        </Box>
                        <Box sx={{ display: 'grid', justifyItems: 'center', marginY: 5, gridTemplateColumns: '1fr 1fr', width: 'fit-content', alignContent: 'center', marginX: 'auto', columnGap: '20px' }}>

                            <Button
                                onClick={() => setOpenModal(true)}
                                sx={{
                                    boxShadow: 'none',
                                    width: '100%',
                                    color: '#c3c3c3',
                                    border: 'solid 1px #c3c3c3',
                                    borderRadius: 0,
                                    padding: '15px 15px'
                                }}
                            >
                                {lng[language]['downloadPlan']}
                            </Button>

                            {
                                apartment.status === 'free' ?
                                    <Button
                                        sx={{
                                            boxShadow: 'none',
                                            width: '100%',
                                            color: '#FFF',
                                            background: '#002856',
                                            border: 'solid 1px #002856',
                                            borderRadius: 0,
                                            padding: '15px 15px',
                                            '&:hover': {
                                                color: '#002856',
                                                background: '#FFF',
                                            }
                                        }}
                                        onClick={
                                            () => 
                                            {
                                                trackCustomEvent('ViewContent', {Apartment_id: id, Date: new Date()});
                                                navigate(`../reserve/${id.id}`);
                                            }
                                        }
                                            >
                                        {lng[language]['takeOffer']}
                                    </Button>
                                    :
                                ''
                            }
                        </Box>

                        <Box sx={{ width: { md: '1024px', xs: '90%' }, marginX: 'auto' }}>
                            <Typography variant="h3" sx={{ fontSize: { md: '47px', xs: '30px' } }}>{lng[language]['amenities']}</Typography>
                            <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr 1fr', xs: '1fr 1fr' }, columnGap: { md: '30px', xs: '20px' }, rowGap: '30px', marginTop: '50px' }}>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='highStandarts' loading="lazy" src='https://fundway.com/images/icons/newIcons/award.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: '20px' }}>{lng[language]['highStandarts']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='beach' loading="lazy" src='https://fundway.com/images/icons/newIcons/umbrella-beach.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['beach']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='travel' loading="lazy" src='https://fundway.com/images/icons/newIcons/travel.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['viewIcons']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='game' loading="lazy" src='https://fundway.com/images/icons/newIcons/Path 2010.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['game']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='Trees' loading="lazy" src='https://fundway.com/images/icons/newIcons/Trees.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['parkIcon']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='gym' loading="lazy" src='https://fundway.com/images/icons/newIcons/gym.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['fitnessIcon']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='parking' loading="lazy" src='https://fundway.com/images/icons/newIcons/parking-circle.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['pargkingIcon']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='safety' loading="lazy" src='https://fundway.com/images/icons/newIcons/shield.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['safetyIcons']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='road' loading="lazy" src='https://fundway.com/images/icons/newIcons/road.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['streetIcon']}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box component={'img'} alt='helicopter-side' loading="lazy" src='https://fundway.com/images/icons/newIcons/helicopter-side.svg' sx={{ width: '35px', height: '35px' }} />
                                    </Box>
                                    <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['heliIcon']}</Typography>
                                </Box>

                            </Box>
                        </Box>
                        {
                            (loading === false && apartment) &&
                            apartment.orientationName === 'Det' &&
                            <SeaView />
                        }

                        {
                            (loading === false && apartment) &&
                            apartment.orientationName === 'Promenade' &&
                            <PromonadeView />
                        }

                        {
                            (loading === false && apartment) &&
                            apartment.orientationName === 'Marine' &&
                            <MarinaView />
                        }

                        {
                            openModal &&
                            <Box sx={{ position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100%', zIndex: 990, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ background: '#FFF', width: 300, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                                    <Box onClick={() => setOpenModal(false)} sx={{ marginLeft: 'auto', width: 'min-content', marginRight: '10px', marginTop: '10px' }}>
                                        <CloseIcon />
                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr auto', gridGap: '20px' }}>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['name']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                sx={inputStyle}
                                                // placeholder='Shruani emrin e plotë'
                                                value={formData.firstname}
                                                error={formDataErrors.firstnameError}
                                                onChange={(e) => inputFunc.firstnameInput(e)}
                                            />
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['lastName']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                sx={inputStyle}
                                                // placeholder='Shruani emrin e plotë'
                                                value={formData.lastname}
                                                error={formDataErrors.lastnameError}
                                                onChange={(e) => inputFunc.lastnameInput(e)}
                                            />
                                        </Box>


                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['countty2']}</InputLabel>
                                            {/* <TextField
                                                fullWidth
                                                sx={inputStyle}
                                                // placeholder='Kosovë'
                                                value={formData.country}
                                                error={formDataErrors.countryError}
                                                onChange={(e) => inputFunc.countryInput(e)}
                                            /> */}
                                            <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': { content: 'none' }, '::after': { content: 'none' }, border: 'solid 1px', borderColor: formDataErrors.countryError ? '#d32f2f !important' : '#c4c4c4', '&:hover': { borderColor: '#000' } }, '.MuiNativeSelect-select': { padding: '15px !important' }, width: '100%' }]}>
                                                <NativeSelect onChange={(e) => inputFunc.countryInput(e)} value={formData.countryId}>
                                                    <option value="">{lng[language]['selectCountry']}</option>
                                                    {
                                                        Countries.map((data, index) => (
                                                            <option key={index} data-countryname={data.country} value={Number(data.countryId)}>{data.country} {data.flag}</option>
                                                        ))
                                                    }
                                                </NativeSelect>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['email']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                sx={inputStyle}
                                                value={formData.email}
                                                error={formDataErrors.emailError}
                                                onChange={(e) => inputFunc.emailInput(e)}
                                            />
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['phone']}</InputLabel>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px', '.MuiSelect-select': { paddingY: '16.5px !important', paddingX: '5px' }, background: '#FFF' }}>
                                                    <Select onChange={(e) => inputFunc.prefixInput(e)} value={formData.prefix} error={formDataErrors.prefixError}>
                                                        {
                                                            Countries.map((data, index) => (
                                                                <MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    type='tel'
                                                    fullWidth
                                                    sx={[{ marginLeft: '10px' }, inputStyle]}
                                                    value={formData.tel}
                                                    error={formDataErrors.telError}
                                                    onChange={(e) => inputFunc.telInput(e)}
                                                />
                                            </Box>
                                        </Box>

                                        <Button
                                            onClick={(e) => !loadingButton ? handleSubmit(e) : null}
                                            sx={{boxShadow: 'none',
                                                width: !loadingButton ? 'max-content' : '140px',
                                                backgroundColor: '#002856',
                                                padding: 1.6,
                                                borderRadius: 0,
                                                color: '#FFF',
                                                justifySelf: 'center',
                                                border: 'solid 1px #002856',
                                                marginY: '20px',
                                                '&:hover': {
                                                    color: '#002856',
                                                    background: '#FFF'
                                                }
                                            }}
                                            disabled={loadingButton ? true : false}
                                        >
                                            {
                                                !loadingButton ?
                                                    lng[language]['downloadPlan']
                                                    :
                                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#FFF' }} />
                                            }
                                        </Button>
                                    </Box>
                                </Box>


                            </Box>
                        }

                        {
                            alert.open &&
                            <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                                <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{ '.MuiAlert-action': { display: 'none' }, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%', borderRadius: 0 }}>
                                    {lng[language][alert.msg]}
                                </Alert>
                            </Box>
                        }

                        {
                            planDetailsModal &&
                            <>
                                <Box sx={{ display: { md: 'none', xs: 'block' }, backgroundColor: '#FFF', position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', zIndex: 999, overflow: 'scroll' }}>
                                    <Box sx={{ gridTemplateRows: 'auto 1fr', display: 'grid' }}>
                                        <Box sx={{ width: '90%', marginX: 'auto', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                                            <CloseIcon onClick={() => setPlanDetailsModal(false)} sx={{ cursor: 'pointer' }} />
                                        </Box>
                                        <Box sx={{ width: '90%', marginX: 'auto' }}>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateRows: '80px 1fr'
                                                }}
                                            >
                                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openSelfPayingPlan ? '#FFF' : '#002856', }}
                                                    onClick={() => setOpenSelfPayingPlan(!openSelfPayingPlan)}
                                                >
                                                    <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayingPlan']}</Typography>
                                                    <Box>
                                                        <KeyboardArrowRightIcon />
                                                    </Box>
                                                </Box>
                                                <AnimatePresence >
                                                    {
                                                        openSelfPayingPlan === true ?
                                                            <Box
                                                                component={motion.section}
                                                                key="content"
                                                                initial="collapsed"
                                                                animate="open"
                                                                exit="collapsed"
                                                                variants={{
                                                                    open: { opacity: 1, height: "auto" },
                                                                    collapsed: { opacity: 0, height: 0 },
                                                                }}
                                                                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                                sx={{ marginTop: '20px' }}
                                                            >

                                                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                                        <Typography>%</Typography>
                                                                        {/* <Typography>&euro;</Typography> */}
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                                        <Typography>10%-5000&euro;</Typography>
                                                                        {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(((10 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100) - 5000)}</Typography> */}
                                                                    </Box>
                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                                        {/* <Typography></Typography> */}
                                                                        <Typography>5000&euro;</Typography>
                                                                    </Box>

                                                                </Box>
                                                                <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id.id}`); }} variant='contained' sx={{ boxShadow: 'none',borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                                    {lng[language]['takeOffer']}
                                                                </Button>
                                                            </Box>
                                                            :
                                                            ''
                                                    }
                                                </AnimatePresence>
                                            </Box>

                                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                    <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openPayingWithBank ? '#002856' : '#002856', }} onClick={() => setOpenPayingWithBank(!openPayingWithBank)}>
                                        <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayWithBank']}</Typography>
                                        <Box><KeyboardArrowRightIcon /></Box>
                                    </Box>

                                    <AnimatePresence initial={false}>
                                        {
                                            openPayingWithBank === true ?
                                                <Box
                                                    component={motion.section}
                                                    key="content"
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={{
                                                        open: { opacity: 1, height: "auto" },
                                                        collapsed: { opacity: 0, height: 0 },
                                                    }}
                                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    sx={{ marginTop: '20px', paddingBottom: '70px' }}
                                                >
                                                    <Box>
                                                        <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>
                                                    </Box>

                                                    <Box>
                                                        <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id.id}`); }} variant='contained' sx={{ boxShadow: 'none',borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                            {lng[language]['takeOffer']}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                :
                                                ''
                                        }
                                    </AnimatePresence>

                                </Box>

                                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openCalculator ? '#002856' : '#002856', }} onClick={() => setOpenCalculator(!openCalculator)}>
                                                    <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['calculator']}</Typography>
                                                    <Box><KeyboardArrowRightIcon /></Box>
                                                </Box>
                                                <AnimatePresence initial={false}>
                                                    {
                                                        openCalculator === true ?
                                                            <Box
                                                                component={motion.section}
                                                                key="content"
                                                                initial="collapsed"
                                                                animate="open"
                                                                exit="collapsed"
                                                                variants={{
                                                                    open: { opacity: 1, height: "auto" },
                                                                    collapsed: { opacity: 0, height: 0 },
                                                                }}
                                                                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                                sx={{ marginTop: '20px', paddingBottom: '70px' }}
                                                            >
                                                                <Box>
                                                                    <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginX: 'auto' }}>
                                                                    {/* <SimpleMortgageCalculator price={Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100} /> */}
                                                                    <NormalCalculator />
                                                                </Box>
                                                                {/* <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                                    {lng[language]['takeOffer']}
                                                                </Button> */}
                                                            </Box>
                                                            :
                                                            ''
                                                    }
                                                </AnimatePresence>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: { md: 'flex', xs: 'none' },
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )',
                                        zIndex: 997,
                                        justifyContent: 'center', alignItems: 'center'
                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            background: '#FFF',
                                            height: 'auto',
                                            paddingY: '20px',
                                            width: '1200px',
                                            gridTemplateColumns: '1fr 1fr',
                                            position: 'relative'
                                        }}
                                    >
                                        <Box sx={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }} onClick={() => setPlanDetailsModal(false)}>
                                            <CloseIcon />
                                        </Box>
                                        <Box sx={{ width: '100%', borderRight: '0.5px solid #c4c4c4' }}>
                                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                                <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayingPlan']}</Typography>
                                                <Box>
                                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                            <Typography>%</Typography>
                                                            {/* <Typography>&euro;</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                            <Typography>10%-5000&euro;</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(((10 / 100) * Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100) - 5000)}</Typography> */}
                                                        </Box>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                            {/* <Typography></Typography> */}
                                                            <Typography>5000&euro;</Typography>
                                                        </Box>

                                                    </Box>
                                                    <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id.id}`); }} variant='contained' sx={{ boxShadow: 'none',borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button>
                                                </Box>


                                                <Box sx={{ marginTop: '25px' }}>

                                                    <Box sx={{ width: '100%', marginX: 'auto', gridTemplateRows: '50px 1fr', display: 'grid' }}>
                                                        <Typography variant="h5" fontSize={'25px'} fontWeight={'normal'} sx={{ width: '100%' }}>{lng[language]['selfPayWithBank']}</Typography>
                                                        <Box>
                                                            <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id.id}`); }} variant='contained' sx={{ boxShadow: 'none',borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                                {lng[language]['takeOffer']}
                                                            </Button>
                                                        </Box>

                                                    </Box>

                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '100%', borderLeft: '0.5px solid #c4c4c4' }}>
                                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                                <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['calculator']}</Typography>
                                                <Box>
                                                <Box>
                                                    <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                                </Box>
                                                    <Box>
                                                        {/* <SimpleMortgageCalculator
                                                            price={Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100}
                                                        /> */}
                                                        <NormalCalculator />
                                                    </Box>
                                                    {/* <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id.id}`); }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button> */}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </>


                            // const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
                            // const [openCalculator, setOpenCalculator] = useState(false);
                        }

                    </Box>
                    :
                    <Loader state={loading} />
            }
        </Box>
    )
}
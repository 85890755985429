import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ListItemButton from '@mui/material/ListItemButton';
import { setLanguage } from '../../app/language/Language';
import ContactModal from '../../pages/public/ContactModal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { lng } from '../../utils/language';
import ClickAwayListener from 'react-click-away-listener';
import { Fade, Typography } from '@mui/material';

const contactBtn = {
    boxShadow: 'none',
    color: '#FFF',
    borderRadius: 0,
    background: '#002856',
    padding: '10px 30px',
    border: 'solid 1px #002856',
    '&:hover': {
        color: '#002856',
        background: '#FFF',
    },
    marginTop: {md: 'unset', xs: '20px'}
}

export default function Header() {

    const [showMenu, setShowMenu] = useState(true);  // Initially show the menu
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);  // Store the last scroll position

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
    
        // If scrolling up, show the menu; if scrolling down, hide the menu
        if (currentScrollY < lastScrollY) {
          setShowMenu(true);
        } else if (currentScrollY > lastScrollY) {
          setShowMenu(false);
        }
    
        // Update the last scroll position
        setLastScrollY(currentScrollY);
      };
    
      useEffect(() => {
        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
      }, [lastScrollY]);

    const language = useSelector((state) => state.language.language)

    const nav = [
        {
            title: lng[language]['residences'].toUpperCase(),
            url: '/properties'
        },
        {
            title: lng[language]['amenities'].toUpperCase(),
            url: '/amenities'
        },
        {
            title: lng[language]['availabitlity'].toUpperCase(),
            url: '/availability'
        },
        {
            title: lng[language]['news'].toUpperCase(),
            url: '/blogs'
        },
    ]

    // const ref = useRef(null);

    const [openModal, setOpenModal] = useState(false);

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [openCollapse, setOpenCollapse] = useState(false);
    const dispatch = useDispatch();

    const languages = [
        {
            lang: 'alb'
        },
        {
            lang: 'eng'
        }
    ]
    const changeLang = (data) => {
        dispatch(setLanguage(data))
        setOpenCollapse(false)
    }
    const [openCalls, setOpenCalls] = useState(false);
    const handleTypologyClickAway = () => {
        if (openCalls) setOpenCalls(false)
        else return
    };

    const [openCallsMobile, setOpenCallsMobile] = useState(false);
    const handleMobile = () => {
        if (openCallsMobile) setOpenCallsMobile(false)
            else return
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }} component={Link} to="/">
                <Box component={'img'} alt='logo' loading="lazy" src='https://fundway.com/images/logo.svg' height={'30px'} />
            </Box>
            <Divider />
            
            <List>
                {nav.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} LinkComponent={Link} to={item.url}>
                            <ListItemText primary={item.title} sx={{ span: { color: '#002856', fontWeight: 600, fontSize: '26px'} }} />
                            <ArrowForwardIosIcon sx={{ color: '#53626F' }} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Button sx={contactBtn} onClick={() => setOpenModal(true)}>
                    {lng[language]['contact']}
                </Button>
            </List> 
        </Box>
    );

    return (
        <Box sx={[{ display: 'flex', transition: '.3s all'}, window.location.pathname !== '/availability' && showMenu ? {position: 'sticky', top: 0, left: 0, zIndex: 99} : {position: 'relative'} ]}>
            <CssBaseline />
            <AppBar component="nav" sx={{ position: 'relative', backgroundColor: '#FFF', zIndex: 99, boxShadow: 0 }}>
                <Toolbar sx={{width: { lg: '1100px', md: '90%',  xs: '100%' }, marginX: 'auto' }}>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: 'none' } }} >
                        <MenuIcon sx={{ fill: '#53626F' }} />
                    </IconButton>
                    <IconButton sx={{ mr: 2, display: { md: 'none' } }}>
                        <Box component={Link} to="/" sx={{display: 'flex'}}>
                            <Box component={'img'} alt='logo' loading="lazy" src='https://fundway.com/images/logo.svg'/>
                        </Box>
                    </IconButton>
                    <IconButton edge="end"  sx={{position: 'relative', display: {md: 'none', xs: 'flex'}, justifyContent: 'center', alignItems: 'center', marginLeft: 'auto'}} disableRipple>
                        <Box onClick={() => setOpenCollapse(!openCollapse)} sx={{color: '#002856', fontSize:'0.875rem', fontWeight: 500}}>
                            {language.toUpperCase()}
                        </Box>
                        {
                            openCollapse &&
                            <Box sx={{position: 'absolute', bottom: {md: '-15px', xs: '-25px'}, padding: 0, margin: 0, cursor: 'pointer'}}  component={'ul'}>
                                {
                                    languages.map((data, index) => (
                                        language === data.lang ?
                                        ''
                                        :
                                        <Box component={'li'} sx={{color: '#002856', border: 'solid 1px #002856', padding: '5px 10px', cursor: 'pointer', backgroundColor: '#FFF', fontSize:'0.875rem', fontWeight: 500}} key={index} onClick={() => changeLang(data.lang)}>
                                            {data.lang.toUpperCase()}
                                        </Box>  
                                    ))
                                }
                            </Box>
                        }
                    </IconButton>
                    <IconButton edge="end" sx={{ ml: 'auto', display: { md: 'none' } }}>
                        <ClickAwayListener onClickAway={handleMobile}>
                            <Box>
                                <Box sx={{ color: '#53626f', width: '100% !important',  display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', height: '100%', borderRadius: '5px' }} onClick={() => setOpenCallsMobile(!openCallsMobile)} >
                                    <WhatsAppIcon sx={{ height: '25px', width: '25px' }} />
                                </Box>
                                <Fade in={openCallsMobile}>
                                    <Box sx={{ position: 'absolute', backgroundColor: '#FFF', zIndex: 999, overflow: 'hidden', transition: '.3s all', top: '54px', right: 0 }}>
                                        <Box component={'a'} href='tel:+38348180018' sx={{ border: 'solid 1px #53626F', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCallsMobile(!openCallsMobile)}>
                                            <Box sx={{ width: '20px', height: '20px' }}>
                                                <Box component={'img'} alt='normal_call' loading="lazy" src='https://fundway.com/images/icons/normal_call.svg' sx={{ width: '100%', height: '100%' }} />
                                            </Box>
                                            <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>CALL</Typography>
                                        </Box>
                                        <Box component={'a'} href={`https://wa.me/${encodeURIComponent('+38348180018')}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCallsMobile(!openCallsMobile)}>
                                            <Box sx={{ width: '20px', height: '20px' }}>
                                                <Box component={'img'} alt='whatsapp' loading="lazy" src='https://fundway.com/images/icons/whatsapp.svg' sx={{ width: '100%', height: '100%' }} />
                                            </Box>
                                            <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>WHATSAPP</Typography>
                                        </Box>
                                        <Box component={'a'} href={`viber://contact?number=${encodeURIComponent('+38348180018')}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCallsMobile(!openCallsMobile)}>
                                            <Box sx={{ width: '20px', height: '20px' }}>
                                                <Box component={'img'} alt='viber' loading="lazy" src='https://fundway.com/images/icons/viber.svg' sx={{ width: '100%', height: '100%' }} />
                                            </Box>
                                            <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>VIBER</Typography>
                                        </Box>
                                    </Box>
                                </Fade>
                            </Box>
                        </ClickAwayListener>
                    </IconButton>

                    <Box sx={{ my: 2, display: { md: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', }} component={Link} to="/">
                        <Box component={'img'} alt='logo' loading="lazy" src='https://fundway.com/images/logo.svg' height={'30px'} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'grid' }, gridTemplateColumns: 'auto auto auto auto auto auto auto', gridGap: '15px', justifyItems: 'center', width: 'max-content', marginLeft: 'auto' }}>
                        {nav.map((item, index) => (
                            <Button key={index} sx={{  boxShadow: 'none',color: '#002856' }} LinkComponent={Link} to={item.url}>
                                {item.title}
                            </Button>
                        ))}
                    <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',}}>
                        <Box onClick={() => setOpenCollapse(!openCollapse)} sx={{color: '#002856', fontSize:'0.875rem', fontWeight: 500}}>
                            {language.toUpperCase()}
                        </Box>
                        {
                            openCollapse &&
                            <Box sx={{position: 'absolute', bottom: '-15px', padding: 0, margin: 0, cursor: 'pointer'}}  component={'ul'}>
                                {
                                    languages.map((data, index) => (
                                        language === data.lang ?
                                        ''
                                        :
                                        <Box component={'li'} sx={{color: '#002856', border: 'solid 1px #002856', padding: '0px 10px', cursor: 'pointer', backgroundColor: '#FFF'}} key={index} onClick={() => changeLang(data.lang)}>
                                            {data.lang.toUpperCase()}
                                        </Box>  
                                    ))
                                }
                            </Box>
                        }
                    </Box>
                    
                    

                    <ClickAwayListener onClickAway={handleTypologyClickAway}>
                        <Box>
                            <Box sx={{ color: '#53626f', width: '100% !important',  display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', height: '100%', borderRadius: '5px' }} onClick={() => setOpenCalls(!openCalls)} >
                                <WhatsAppIcon sx={{ height: '30px', width: '30px' }} />
                            </Box>
                            <Fade in={openCalls}>
                                <Box sx={{ position: 'absolute', backgroundColor: '#FFF', zIndex: 2, overflow: 'hidden', transition: '.3s all', top: '60px' }}>
                                    <Box component={'a'} href='tel:+38348180018' sx={{ border: 'solid 1px #53626F', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '20px', height: '20px' }}>
                                            <Box component={'img'} alt='normal_call' loading="lazy" src='https://fundway.com/images/icons/normal_call.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>CALL</Typography>
                                    </Box>
                                    <Box component={'a'} href={`https://wa.me/${encodeURIComponent('+38348180018')}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '20px', height: '20px' }}>
                                            <Box component={'img'} alt='whatsapp' loading="lazy" src='https://fundway.com/images/icons/whatsapp.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>WHATSAPP</Typography>
                                    </Box>
                                    <Box component={'a'} href={`viber://contact?number=${encodeURIComponent(+38348180018)}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '20px', height: '20px' }}>
                                            <Box component={'img'} alt='viber' loading="lazy" src='https://fundway.com/images/icons/viber.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" component={'p'} color={'#53626F'} sx={{ marginLeft: '10px' }}>VIBER</Typography>
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    </ClickAwayListener>

                    <Button sx={[contactBtn, {width: 'fit-content'}]} onClick={() => setOpenModal(true)}>
                        {lng[language]['contact']}
                    </Button> 

                        {/* <Box width={'30px'} height={'30px'} border={'solid 1px #000'} borderRadius={'100%'}>

                        </Box>
                        <Box width={'30px'} height={'30px'} border={'solid 1px #000'} borderRadius={'100%'}>

                        </Box>
                        <Box width={'30px'} height={'30px'} border={'solid 1px #000'} borderRadius={'100%'}>

                        </Box> */}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{keepMounted: true}} sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 1 }, }} >
                    {drawer}
                </Drawer>
            </nav>

            {
                openModal &&
                <Box sx={{position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100vh', zIndex: 9990, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactModal setState={setOpenModal} />
                </Box>
            }

        </Box>
    )
}
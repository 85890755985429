import React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ state }) {
    return (
        <Backdrop sx={{ zIndex: 995, color: '#000', width: '100%' }} open={state}>
            <Box sx={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box component={'img'} alt='loader' loading="lazy" src={'https://fundway.com/images/2024/loader.svg'} sx={{ position: 'fixed', zIndex: 2, width: '70px' }} />
                <CircularProgress sx={{ width: '120px !important', height: '120px !important', color: '#002856' }} />
            </Box>
        </Backdrop>
    )
}
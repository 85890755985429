import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FetchData from '../../api/FetchData';
import Loader from '../../components/loader/Loader';
import { Button, Typography } from '@mui/material';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { financingType, insertItems, registerItems } from '../../utils/utils';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalculatorContainer from '../../components/CalculatorContainer';

export default function Compare() {

    const params = useParams();
    const language = useSelector((state) => state.language.language);
    const [compareAps, setCompareAps] = useState([
        { compare: false },
        { compare: false },
        { compare: false }
    ]);
    //eslint-disable-next-line
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    // const isMobile = width <= 899;

    const [loading, setLoading] = useState(true);
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (params.id) {
            registerItems(params.id);
        }
        FetchData.create('/apartments/getSpecificApartemnt', {
            apartmentId: params.id
        })
            .then(res => {
                if (res?.data?.success) {
                    const updatedCompareAps = res?.data?.data.map((apartment) => ({
                        compare: true,
                        ...apartment,
                    }));
                    while (updatedCompareAps.length < compareAps.length) {
                        updatedCompareAps.push({ compare: false });
                    }
                    setCompareAps(updatedCompareAps);
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            })
        // eslint-disable-next-line
    }, [params.id, location])



    const removeApartment = (index, appId) => {
        const newArr = compareAps.map((item, i) => i === index ? { compare: false } : item);
        const Ids = newArr.map((item) => item.apartmentId ? item.apartmentId : null)
        insertItems(appId, true);
        updateUrlWithApartmentId(Ids);
    };
    const navigate = useNavigate();
    const updateUrlWithApartmentId = (apartmentId) => {
        setLoading(true)
        var filtered = apartmentId.filter(function (el) {
            return el != null;
        });
        if (filtered.length === 0) {
            navigate(`/properties`);
        } else {
            navigate(`/compare/${filtered}`);
        }
    };

    // const updateState = (id, price, status) => {
    //     fbq("track", "Payment Plan", {
    //         Apartment_id: id, 
    //         Date: new Date(),
    //         Status: status
    //     });
    //     if (isMobile) {
    //         setPriceId({
    //             ...priceAndId,
    //             id: id,
    //             price: price,
    //             status: status
    //         })
    //         setMobileModal(true)
    //     }else{
    //         setPriceId({
    //             ...priceAndId,
    //             id: id,
    //             price: price,
    //             status: status
    //         })
    //         setDesktopModal(true)
    //     }
    // }
//eslint-disable-next-line
    const [priceAndId, setPriceId] = useState({
        id: '',
        price: '',
        status: ''
    })

    const [desktopModal, setDesktopModal] = useState(false);

    const [mobileModal, setMobileModal] = useState(false);

    const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
    const [openPayingWithBank, setOpenPayingWithBank] = useState(false);

    const [openCalculator, setOpenCalculator] = useState(false);

    const desktop = (
        <Box sx={{ display: { md: 'grid', xs: 'none' }, width: { md: '1024px', xs: 'unset' }, marginX: 'auto', gridTemplateColumns: { md: 'repeat(4, 1fr)', xs: '1fr 1fr' }, gridTemplateRows: { md: '250px 350px 450px 100px', xs: '' }, paddingTop: '50px' }}>
            {/* SPANS START */}
            <Box component={'span'} sx={{ gridColumnStart: 1, gridColumnEnd: 5, gridRowStart: 2, gridRowEnd: 3, height: '100%', width: '100%', borderTop: 'solid 2px #DEE2E6', borderBottom: 'solid 2px #DEE2E6', display: 'grid' }} >
                <Box sx={{ background: '#e6e6e6' }}></Box>
                <Box></Box>
                <Box sx={{ background: '#e6e6e6' }}></Box>
                <Box></Box>
                <Box sx={{ background: '#e6e6e6' }}></Box>
                {/* <Box></Box> */}
            </Box>
            <Box component={'span'} sx={{ gridColumnStart: 2, gridColumnEnd: 2, gridRowStart: 1, gridRowEnd: 5, height: '100%', width: '100%', borderLeft: 'solid 2px #DEE2E6', zIndex: 2, position: 'relative' }}></Box>
            <Box component={'span'} sx={{ gridColumnStart: 3, gridColumnEnd: 3, gridRowStart: 1, gridRowEnd: 5, height: '100%', width: '100%', borderLeft: 'solid 2px #DEE2E6', zIndex: 2, position: 'relative' }}></Box>
            <Box component={'span'} sx={{ gridColumnStart: 4, gridColumnEnd: 4, gridRowStart: 1, gridRowEnd: 5, height: '100%', width: '100%', borderLeft: 'solid 2px #DEE2E6', zIndex: 2, position: 'relative' }}></Box>
            <Box component={'span'} sx={{ gridColumnStart: 5, gridColumnEnd: 5, gridRowStart: 1, gridRowEnd: 5, height: '100%', width: '100%', borderLeft: 'solid 2px #DEE2E6', zIndex: 2, position: 'relative' }}></Box>
            {/* SPANS END */}
            <Box sx={{ gridColumnStart: '1', gridColumnEnd: '2', gridRowStart: 1, gridRowEnd: 5, display: 'grid', gridTemplateRows: '250px 350px 200px 100px' }}>
                <Box sx={{ padding: '20px', display: 'grid' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <Typography fontWeight={'normal'} fontSize={'30px'} marginTop={2}>{lng[language]['comapreResidencies2']}</Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: '0 20px', display: 'grid' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['flootTxt']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['bedsTxt']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['orientation']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['terraceTxt']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['areaTxt']}</Typography>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['priceTxt']}</Typography>
                    </Box> */}
                </Box>
                <Box sx={{ padding: '0 20px', display: 'grid' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography fontWeight={'normal'} fontSize={'16px'}>{lng[language]['amenities'].toUpperCase()}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    </Box> */}
                </Box>
                <Box sx={{ padding: '20px', display: 'grid' }}></Box>
            </Box>
            {
                compareAps?.map((data, index) => (
                    <Box key={index}
                        sx={[
                            index === 0 && { gridColumnStart: '2', gridColumnEnd: '3', gridRowStart: 1, gridRowEnd: 5 },
                            index === 1 && { gridColumnStart: '3', gridColumnEnd: '4', gridRowStart: 1, gridRowEnd: 5 },
                            index === 2 && { gridColumnStart: '4', gridColumnEnd: '5', gridRowStart: 1, gridRowEnd: 5 },
                            { display: 'grid', gridTemplateRows: '250px 350px 450px 100px' }
                        ]
                        }>
                        <Box sx={{ padding: '20px', display: 'grid' }}>
                            {
                                data.compare === false ?
                                    <Button onClick={() => navigate('/properties')} variant='contained' sx={{ color: '#707070', width: 'max-content', boxShadow: 'none', height: 'max-content', marginX: 'auto', border: 'solid 1px #707070', background: 'transparent', borderRadius: 0, '&:hover': { color: '#FFF', backgroundColor: '#707070' }, position: 'relative', zIndex:2 }}>+ {lng[language]['compare']} </Button>
                                    :
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="h5" fontSize={'21px'} textAlign={'center'}>{language === 'eng'?data.apartmentName.replace('Apartamenti', 'Apartment'):data.apartmentName}</Typography>
                                            <Box sx={{ width: '20px', height: '20px', cursor: 'pointer', color: '#707070', border: 'solid 1px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', zIndex: 2 }} onClick={() => removeApartment(index, data.apartmentId)}>
                                                <CloseIcon />
                                            </Box>
                                        </Box>
                                        <Box sx={{ height: '100%' }}>
                                            <Box component={'img'} alt={data?.imageName} loading="lazy" src={`https://www.fundway.com/images/apartments/${data?.imageName}`} sx={{ width: 1, height: 1, objectFit: 'cover' }} />
                                        </Box>
                                    </>
                            }
                        </Box>

                        <Box sx={{ display: 'grid', padding: '0 20px' }}>
                            {
                                data.compare === false ?
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='stair' loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: '25px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>+{data.floorNo}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='typology' loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: '25px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{data.typology}</Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='orientation' loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: '25px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language][data.orientationName]}</Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='terrace' loading="lazy" src='https://fundway.com/images/2024/terrace.svg' sx={{ width: '25px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{data.surfaceTerrace ? data.surfaceTerrace : 0} M²</Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='scalability' loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: '25px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{data.surfaceGrossWithTerrace} M²</Typography>
                                        </Box>
                                        {/* {
                                                data.status !== 'free'
                                                ?
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/money.svg' sx={{ width: '30px' }} />
                                                    <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}></Typography>
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/money.svg' sx={{ width: '30px' }} />
                                                    <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{new Intl.NumberFormat('us-Us', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(data.priceTotal) + parseInt(data.priceParking)) / 100) * 100)}</Typography>
                                                </Box>
                                            } */}
                                       
                                    </>
                            }
                        </Box>
                        <Box sx={{ display: 'grid', padding: '0 20px' }}>
                            {
                                data.compare === false ?
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='game' loading="lazy" src='https://fundway.com/images/icons/Path 2010.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language]['gameCorner']}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='gym' loading="lazy" src='https://fundway.com/images/icons/gym.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>Fitness</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='Trees' loading="lazy" src='https://fundway.com/images/icons/Trees.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>Park</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='security' loading="lazy" src='https://fundway.com/images/icons/shield.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language]['security']}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='view' loading="lazy" src='https://fundway.com/images/icons/travel.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language]['view']}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='road' loading="lazy" src='https://fundway.com/images/icons/road.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language]['road']}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Box component={'img'} alt='helicopter' loading="lazy" src='https://fundway.com/images/icons/helicopter-side.svg' sx={{ width: '20px' }} />
                                            <Typography fontWeight={'normal'} fontSize={'16px'} paddingLeft={'10px'}>{lng[language]['heli']}</Typography>
                                        </Box>
                                    </>
                            }
                        </Box>
                        <Box sx={{ display: 'grid', padding: '20px', justifyItems: 'flex-start', alignItems: 'center', position: 'relative', zIndex: 4 }}>
                            {
                                data.compare === false ? '' :
                                data.status !== 'free'?
                                <Button onClick={() => {financingType('specialOffer'); navigate(`../reserve/${data.apartmentId}`)}} sx={{ border: 'solid 1px #002856', boxShadow: 'none', borderRadius: '0', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' } }}>{lng[language]['takeOffer'].toUpperCase()}</Button>
                                :
                                // <Button onClick={() => updateState(data.apartmentId, Math.ceil((parseFloat(data.priceTotal) + parseInt(data.priceParking)) / 100) * 100, data.status)} sx={{ border: 'solid 1px #002856', borderRadius: '0', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' } }}>{lng[language]['paymentPlan'].toUpperCase()}</Button>
                                <Button onClick={() => {navigate(`../reserve/${data.apartmentId}`)}} sx={{ border: 'solid 1px #002856', borderRadius: '0', boxShadow: 'none', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' } }}>{lng[language]['takeOffer'].toUpperCase()}</Button>
                            }
                        </Box>
                       

                    </Box>
                ))
            }
        </Box>
    );

    const [openCompare, setOpenCompare] = useState(true);
    const [openInfo, setOpenInfo] = useState(true);
    const [openAmenities, setOpenAmenities] = useState(true);
    // const [openPosition, setOpenPoistion] = useState(true);

    const mobile = (
        <Box sx={{ display: { md: 'none', xs: 'block' }, marginTop: '25px', width: '90%', marginX: 'auto' }}>
            {/* KRAHASO START */}
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ fontSize: '21px', fontWeight: 'bold' }}>{lng[language]['comapreResidencies']}</Typography>
                    <Box onClick={() => setOpenCompare(!openCompare)}>
                        <KeyboardArrowUpIcon sx={{transition: '.3s all', rotate: openCompare ? 0 : '180deg'}} />
                    </Box>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '30px', marginTop: '20px', transition: '.3s all', height: openCompare ? 'auto' : 0, overflow: openCompare ? 'unset' : 'hidden'  }}>
                    {
                        compareAps[0].compare === true &&
                        <Box sx={{ border: 'solid 1px' }}>
                            <Box sx={{ width: 'max-content', marginLeft: 'auto', marginRight: 2, marginTop: 2 }} onClick={() => removeApartment(0, compareAps[0].apartmentId)}>
                                <CloseIcon />
                            </Box>
                            <Typography variant="subtitle1" marginLeft={2} paddingBottom={2} fontSize={'16px'}>{language === 'eng'?compareAps[0].apartmentName.replace('Apartamenti', 'Apartment'):compareAps[0].apartmentName} <br /> {language === 'eng'?compareAps[0].buildingName.replace('Godina', 'Building'):compareAps[0].buildingName}</Typography>
                        </Box>
                    }
                    {
                        compareAps[1].compare === true &&
                        <Box sx={{ border: 'solid 1px' }}>
                            <Box sx={{ width: 'max-content', marginLeft: 'auto', marginRight: 2, marginTop: 2 }} onClick={() => removeApartment(0, compareAps[0].apartmentId)}>
                                <CloseIcon />
                            </Box>
                            <Typography variant="subtitle1" marginLeft={2} paddingBottom={2} fontSize={'16px'}>{language === 'eng'?compareAps[1].apartmentName.replace('Apartamenti', 'Apartment'):compareAps[1].apartmentName} <br /> {language === 'eng'?compareAps[1].buildingName.replace('Godina', 'Building'):compareAps[1].buildingName}</Typography>
                        </Box>
                    }
                    {
                        compareAps[1].compare === false &&
                        <Box sx={{ border: 'solid 1px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Button onClick={() => navigate('/properties')} variant='contained' sx={{ color: '#707070', width: 'max-content', height: 'max-content', marginX: 'auto', boxShadow: 'none', border: 'solid 1px #707070', background: 'transparent', borderRadius: 0, '&:hover': { color: '#FFF', backgroundColor: '#707070' } }}>+ {lng[language]['compare']} </Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            {/* KRAHASO END */}

            {/* INFORMATAT E REZIDENCAVE START */}
            <Box sx={{ marginTop: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ fontSize: '17px', fontWeight: 'bold' }}>{lng[language]['infoRes']}</Typography>
                    <Box onClick={() => setOpenInfo(!openInfo)}>
                        <KeyboardArrowUpIcon sx={{ rotate: openInfo ? 0 : '180deg', transition: '.3s all' }} />
                    </Box>
                </Box>
                <Box sx={{ transition: '.3s all', height: openInfo ? 'auto' : 0, overflow: openInfo ? 'unset' : 'hidden' }}>
                    {/* FLOOR */}
                    <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} alt='stair' loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['floor']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>+{compareAps[0].floorNo}</Typography>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                <Box>
                                    <Box>
                                        <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>+{compareAps[1].floorNo}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                    {/* BEDS */}
                    <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} alt='beds' loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['bedsTxt']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{compareAps[0].typology}</Typography>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                <Box>
                                    <Box>
                                        <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{compareAps[1].typology}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>

                    </Box>

                    {/* TARRACE */}
                    <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} alt='terrace' loading="lazy" src='https://fundway.com/images/2024/terrace.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['terraceTxt']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{(compareAps[0].surfaceTerrace) ? compareAps[0].surfaceTerrace : 0} M²</Typography>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                <Box>
                                    <Box>
                                        <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{(compareAps[1].surfaceTerrace) ? compareAps[1].surfaceTerrace : 0} M²</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                    {/* AREA */}
                    <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} alt='scalability' loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['areaTxt']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{compareAps[0].surfaceGrossWithTerrace} M²</Typography>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                <Box>
                                    <Box>
                                        <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{compareAps[1].surfaceGrossWithTerrace} M²</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                    {/* PLAN */}
                    <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} alt='blueprint' loading="lazy" src='https://fundway.com/images/icons/blueprint.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['plan']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#002856' }} component={'a'} href={`https://vloramarina.fundway.com/${compareAps[0].pdfFilePath}`} target='blank_'>
                                        <Typography variant="subtitle1" marginLeft={'10px'} marginRight={'10px'} textAlign={'center'} fontSize={'16px'}>{lng[language]['plan']}</Typography>
                                        <Box><Box component={'img'} alt='pdfPlan' loading="lazy" src='https://fundway.com/images/icons/Group 154.svg' sx={{ width: '15px', height: '15px' }} /></Box>
                                    </Box>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#002856' }} component={'a'} href={`https://vloramarina.fundway.com/${compareAps[1].pdfFilePath}`} target='blank_'>
                                        <Typography variant="subtitle1" marginLeft={'10px'} marginRight={'10px'} textAlign={'center'} fontSize={'16px'}>{lng[language]['plan']}</Typography>
                                        <Box><Box component={'img'} alt='pdfPlan' loading="lazy" src='https://fundway.com/images/icons/Group 154.svg' sx={{ width: '15px', height: '15px' }} /></Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                    {/* PRICE */}
                    {/* <Box marginTop={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/money.svg' sx={{ width: '25px', height: '25px' }} />
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 1.5 }}>{lng[language]['priceTxt']}</Typography>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 1, borderBottom: 'solid 1px #C9CFD3', paddingBottom: 1.5 }}>
                            {
                                compareAps[0].compare === true &&
                                compareAps[0].status !== 'free'
                                ?
                                ''
                                :
                                <Box sx={{ borderRight: '1px solid #C9CFD3' }}>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(compareAps[0].priceTotal) + parseInt(compareAps[0].priceParking)) / 100) * 100)}</Typography>
                                </Box>
                            }
                            {
                                compareAps[1].compare === true &&
                                compareAps[1].status !== 'free'
                                ?
                                ''
                                :
                                <Box>
                                    <Typography variant="subtitle1" textAlign={'center'} fontSize={'16px'}>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(compareAps[1].priceTotal) + parseInt(compareAps[1].priceParking)) / 100) * 100)}</Typography>
                                </Box>
                            }
                        </Box>
                    </Box> */}

                </Box>
            </Box>
            {/* INFORMATAT E REZIDENCAVE END */}

            {/* AMENITIES START */}
            <Box sx={{ marginTop: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ fontSize: '21px', fontWeight: 'bold' }}>{lng[language]['amenities']}</Typography>
                    <Box onClick={() => setOpenAmenities(!openAmenities)}>
                        <KeyboardArrowUpIcon sx={{ transition: '.3s all', rotate: openAmenities ? 0 : '180deg' }} />
                    </Box>
                </Box>
                <Box sx={[{ display: 'grid', gridTemplateColumns: '1fr 1px 1fr', columnGap: '20px', marginTop: '20px', transition: '.3s all' }, openAmenities ? { height: 'auto', overflow: 'unset' } : { height: '0px', overflow: 'hidden' }]}>
                    {
                        compareAps[0].compare === true &&
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                    <Box component={'img'} alt='game' loading="lazy" src='https://fundway.com/images/icons/Path 2010.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['gameCorner']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='gym' loading="lazy" src='https://fundway.com/images/icons/gym.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>Fitness</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='Park' loading="lazy" src='https://fundway.com/images/icons/Trees.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>Park</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='security' loading="lazy" src='https://fundway.com/images/icons/shield.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['security']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='view' loading="lazy" src='https://fundway.com/images/icons/travel.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['view']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='road' loading="lazy" src='https://fundway.com/images/icons/road.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['road']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='helicopter' loading="lazy" src='https://fundway.com/images/icons/helicopter-side.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['heli']}</Typography>
                            </Box>
                        </Box>
                    }
                    <Box sx={{ height: '100%', width: '100%', backgroundColor: '#C9CFD3' }}></Box>
                    {
                        compareAps[1].compare === true &&
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                    <Box component={'img'} alt='game' loading="lazy" src='https://fundway.com/images/icons/Path 2010.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['gameCorner']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='gym' loading="lazy" src='https://fundway.com/images/icons/gym.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>Fitness</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='Park' loading="lazy" src='https://fundway.com/images/icons/Trees.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>Park</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='security' loading="lazy" src='https://fundway.com/images/icons/shield.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['security']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='travel' loading="lazy" src='https://fundway.com/images/icons/travel.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['view']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='road' loading="lazy" src='https://fundway.com/images/icons/road.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['road']}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <Box>
                                    <Box component={'img'} alt='helicopter' loading="lazy" src='https://fundway.com/images/icons/helicopter-side.svg' sx={{ width: '25px', height: '25px' }} />
                                </Box>
                                <Typography marginLeft={1} variant="subtitle1" fontSize={'13px'}>{lng[language]['heli']}</Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            {/* AMENITIES END */}

            {/* BUTTONS */}
            <Box sx={{ marginTop: '30px' }}>

                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1px 1fr', columnGap: '20px', borderTop: 'solid 1px #C9CFD3', borderBottom: 'solid 1px #C9CFD3', padding: '15px 0' }}>

                    {
                        compareAps[0].compare === true &&
                        compareAps[0].status !== 'free'
                        ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button onClick={() => {financingType('specialOffer'); navigate(`../reserve/${compareAps[0].apartmentId}`)}} sx={{ border: 'solid 1px #002856', borderRadius: '0', boxShadow: 'none', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' } }}>
                                {lng[language]['takeOffer'].toUpperCase()}
                            </Button>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <Button  onClick={() => updateState(compareAps[0].apartmentId, Math.ceil((parseFloat(compareAps[0].priceTotal) + parseInt(compareAps[0].priceParking)) / 100) * 100, compareAps[0].status)} variant='contained' sx={{ borderRadius: 0, border: 'solid 1px #002856', boxShadow: 'none', backgroundColor: '#002856', color: '#FFF', '&:hover': { backgroundColor: '#FFF', color: '#002856' }, paddingY: '10px' }}>
                                {lng[language]['paymentPlan']}
                            </Button> */}
                            <Button onClick={() => {navigate(`../reserve/${compareAps[0].apartmentId}`)}} sx={{ border: 'solid 1px #002856', borderRadius: '0', boxShadow: 'none', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' }, paddingY: '10px' }}>{lng[language]['takeOffer'].toUpperCase()}</Button>
                        </Box>
                    }
                    <Box sx={{ height: '100%', width: '100%', backgroundColor: '#C9CFD3' }}></Box>
                    {
                        compareAps[1].compare === true &&
                        compareAps[1].status !== 'free'
                        ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button onClick={() => {financingType('specialOffer'); navigate(`../reserve/${compareAps[1].apartmentId}`)}} sx={{ border: 'solid 1px #002856', borderRadius: '0',boxShadow: 'none', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' } }}>
                                {lng[language]['takeOffer'].toUpperCase()}
                            </Button>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <Button  onClick={() => updateState(compareAps[1].apartmentId, Math.ceil((parseFloat(compareAps[1].priceTotal) + parseInt(compareAps[1].priceParking)) / 100) * 100, compareAps[0].status)} variant='contained' sx={{ borderRadius: 0, border: 'solid 1px #002856', backgroundColor: '#002856', color: '#FFF', '&:hover': { backgroundColor: '#FFF', color: '#002856' }, paddingY: '10px' }}>
                                {lng[language]['paymentPlan']}
                            </Button> */}
                            <Button onClick={() => {navigate(`../reserve/${compareAps[1].apartmentId}`)}} sx={{ border: 'solid 1px #002856', borderRadius: '0', boxShadow: 'none', background: '#002856', color: '#FFF', width: 'max-content', '&:hover': { background: '#FFF', color: '#002856' }, paddingY: '10px' }}>{lng[language]['takeOffer'].toUpperCase()}</Button>
                        </Box>
                    }

                </Box>

            </Box>
        </Box>
    );

    return (
        <Box sx={{ color: '#002856' }}>
            {
                loading === false
                    ?
                    <>
                        {desktop}

                        {mobile}

                        {
                            <CalculatorContainer 
                                price={priceAndId.price}
                                id={priceAndId.id}
                                status={priceAndId.status}
                                desktopModal={desktopModal}
                                setDesktopModal={setDesktopModal}
                                setMobileModal={setMobileModal}
                                mobileModal={mobileModal}
                                openSelfPayingPlan={openSelfPayingPlan}
                                setOpenSelfPayingPlan={setOpenSelfPayingPlan}
                                openPayingWithBank={openPayingWithBank}
                                setOpenPayingWithBank={setOpenPayingWithBank}
                                openCalculator={openCalculator}
                                setOpenCalculator={setOpenCalculator}
                            />
                        }

                    </>
                    :
                    <Loader state={loading} />
            }

        </Box>
    )
}

import { useEffect } from 'react';
import { api } from '../../api/config';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Loader from '../../components/loader/Loader';
import { lng } from '../../utils/language';

export default function Blogs() {

    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const language = useSelector((state) => state.language.language);

    const handleObtain = () => {
        setLoading(true)
        api.post('/page/getBlogs', { lang: language })
        .then(res => {
            setBlogs(res?.data?.blogs);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        handleObtain();
        //eslint-disable-next-line
    }, [language])

    return (
        <div>
            <Box sx={{height: {lg: '700px', xs: '600px'}, backgroundImage: "url('https://fundway.com/images/2025/Blog-picture.jpg')", display: 'flex', alignItems: 'end'}}>
                <Box sx={{width: {xl: '900px', lg: '900px', md: '850px', xs: '100%'}, marginX: 'auto', padding: {md: '20px 40px', xs: '20px'}, background: '#FFF', display: 'grid', gridTemplateRows: 'auto', rowGap: '20px'}}>
                    <Typography variant="body2" color={'#707070'} fontSize={'12px'} display={'flex'} alignItems={'center'}>{lng[language]['blogStory']}<Box component={'span'} display={'block'} marginLeft={'5px'} width={'70px'} height={'1px'} sx={{background: '#707070'}}></Box></Typography>
                    <Typography variant="h4" color={'#002856'} fontSize={{lg: '22px', xs: '20px'}} width={{lg: '65%', xs: '100%'}} fontFamily={'Flatline'} fontWeight={'bold'}>{lng[language]['blogMainHeader']}</Typography>
                    <Typography variant="body1" color={'#707070'} fontSize={{sm: '16px', xs: '12px'}}>{lng[language]['blogMainDesc']}</Typography>
                </Box>
            </Box>
            {
                !loading ?
                    <Box sx={{width: {lg: '1024px', md: '70%', sm: '80%', xs: '90%'}, marginX: 'auto', paddingTop: '50px'}}>
                        {
                            blogs?.length > 0
                            ?
                             blogs[0] && 
                            <Box width={{md: '100%', xs: '100%'}} marginX={{md: 'unset', xs: 'auto'}} display={'grid'} sx={{gridTemplateColumns: {md: '1fr 1fr', xs: '1fr'}, cursor: 'pointer'}} onClick={() => navigate(blogs[0].slug)}>
                                {/* Image */}
                                <Box width={'100%'} height={{md: 380, xs: 250}}>
                                    <Box component={'img'} alt={blogs?.[0]?.image} loading="lazy" src={blogs?.[0]?.image} sx={{objectFit: 'cover', width: 1, height: 1}} />
                                </Box>
                                {/* Text */}
                                <Box sx={{display: 'grid', gridTemplateRows: 'auto', rowGap: {md: '20px', xs: '10px'}, padding: {md: '20px', xs: '10px 20px'}, height: {md: '70%', xs: '100%'}, gridTemplateColumns: {md: 'unset', xs: '1fr 1fr'}, alignContent: 'center', width: {md: '70%', xs: '100%'}, marginX: 'auto' }}>
                                    <Typography variant="body2" color={'#707070'} fontSize={'12px'} sx={{gridRowEnd: {md: 'unset', xs: 3}, gridColumnStart: {md: 'unset', xs: 1}, gridColumnEnd: {md: 'unset', xs: 3}}}>{blogs[0].uploadDate}</Typography>
                                    <Typography variant="h4" color={'#002856'} fontSize={{lg: '22px', xs: '22px'}} sx={{gridRowEnd: {md: 'unset', xs: 1}, gridColumnStart: {md: 'unset', xs: 1}, gridColumnEnd: {md: 'unset', xs: 3}}}>{blogs[0].title}</Typography>
                                    <Typography variant="body1" color={'#071C35'} fontSize={{lg: '18px', xs: '16px'}} sx={{gridRowEnd: {md: 'unset', xs: 2}, gridColumnStart: {md: 'unset', xs: 1}, gridColumnEnd: {md: 'unset', xs: 3}}}>{blogs[0].description}</Typography>
                                </Box>
                            </Box>
                            :
                            null
                        }
                        {
                            blogs?.length > 1 &&
                            <Box sx={{display: 'grid', gridTemplateColumns: {md: 'repeat(3, 1fr)', sm: '1fr 1fr', xs: '1fr'}, columnGap: '20px', marginTop: '50px'}} >
                                {blogs.map((data, index) => (
                                    index !== 0 &&
                                    <Box key={data.id} border={{md: 'solid 1px #707070', xs: 'none'}} sx={{cursor: 'pointer'}} onClick={() => navigate(data.slug)}>
                                        {/* Cover Image */}
                                        <Box height={180} width={'100%'}>
                                            <Box component={'img'} alt={data?.image} loading="lazy" src={data?.image} sx={{height: 1, width: 1, objectFit: 'cover'}} />
                                        </Box>
                                        {/* Text */}
                                        <Box sx={{display: 'grid', gridTemplateRows: 'auto', rowGap: {md: '20px', xs: '10px'}, padding: {md: '20px', xs: '10px 20px'}}}>
                                            <Typography variant="body2" color={'#707070'} fontSize={'12px'} sx={{gridRowEnd: {md: 'unset', xs: 3}}}>{data.uploadDate}</Typography>
                                            <Typography variant="h4" color={'#002856'} fontSize={{lg: '22px', xs: '22px'}} sx={{gridRowEnd: {md: 'unset', xs: 1}}}>{data.title}</Typography>
                                            <Typography variant="body1" color={'#071C35'} fontSize={{lg: '18px', xs: '16px'}} sx={{gridRowEnd: {md: 'unset', xs: 2}}}>{data.description}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        }
                    </Box>
                    :
                    <Loader state={loading} />
            }
        </div>
    )
}